export default {
  modalTitle: {
    id: 'buyback_my_sales_counteroffer_modal_name',
    defaultMessage: 'Counteroffer',
  },
  rejectButton: {
    id: 'buyback_my_sales_counteroffer_modal_reject',
    defaultMessage: 'Reject',
  },
  acceptButton: {
    id: 'buyback_my_sales_counteroffer_modal_accept',
    defaultMessage: 'Accept',
  },
  noMatch: {
    id: 'buyback_my_sales_counteroffer_modal_no_match',
    defaultMessage:
      'After some checks and tests, the refurbisher saw that the item you sent doesn’t match the assessment.',
  },
  description: {
    id: 'buyback_my_sales_counteroffer_modal_description',
    defaultMessage:
      'The refurbisher is still interested and has sent you a counteroffer based on the actual condition of your item.',
  },
  title: {
    id: 'buyback_my_sales_counteroffer_modal_title',
    defaultMessage: 'What the refurbisher found',
  },
  tag: {
    id: 'buyback_my_sales_counteroffer_modal_days_left',
    defaultMessage: `{count, plural, 
        =0 {Last day}
        other {# days left}
      } to respond.`,
  },
  reason: {
    id: 'buyback_my_sales_counteroffer_modal_reason',
    defaultMessage: 'Reason',
  },
  commentTitle: {
    id: 'buyback_my_sales_counteroffer_modal_comment_title',
    defaultMessage: 'Refubisher’s comment',
  },
  nextTitle: {
    id: 'buyback_my_sales_counteroffer_modal_what_next_title',
    defaultMessage: 'What happens next?',
  },
  next1: {
    id: 'buyback_my_sales_counteroffer_modal_what_next_1',
    defaultMessage:
      'If you accept the counteroffer, the refurbisher has 2 business days to send you the money. You’ll see the cash in your bank account within 6 business days after the refurbisher sends payment.',
  },
  next2: {
    id: 'buyback_my_sales_counteroffer_modal_what_next_2',
    defaultMessage:
      'If you reject the counteroffer, the refurbisher has 2 business days to send the device back to you at no cost.',
  },
  getHelp: {
    id: 'buyback_my_sales_counteroffer_modal_what_get_help',
    defaultMessage: 'Get help',
  },
  alternativeController: {
    id: 'alternative-controller',
    defaultMessage: 'Controller',
  },
  alternativeCurrent: {
    id: 'alternative-current',
    defaultMessage: 'Current Slide',
  },
  alternativeNext: {
    id: 'alternative-next',
    defaultMessage: 'Next',
  },
  alternativePrevious: {
    id: 'alternative-previous',
    defaultMessage: 'Previous',
  },
  close: {
    id: 'buyback_my_sales_bank_details_modal_close',
    defaultMessage: 'Close',
  },
  toastCounterOfferErrorTitle: {
    id: 'buyback_my_sales_counteroffer_modal_toast_error_title',
    defaultMessage: 'Doh!',
  },
  toastCounterOfferAcceptTitle: {
    id: 'buyback_my_sales_counteroffer_modal_toast_accept_title',
    defaultMessage: 'Counteroffer accepted!',
  },
  toastCounterOfferRejectTitle: {
    id: 'buyback_my_sales_counteroffer_modal_toast_reject_title',
    defaultMessage: 'Sweet, sweet rejection',
  },
  toastCounterOfferErrorContent: {
    id: 'buyback_my_sales_counteroffer_modal_toast_error_content',
    defaultMessage: 'There was a technical glitch. Try again.',
  },
  toastCounterOfferAcceptContent: {
    id: 'buyback_my_sales_counteroffer_modal_toast_accept_content',
    defaultMessage:
      'You’ll see money hit your bank account within 6 business days.',
  },
  toastCounterOfferRejectContent: {
    id: 'buyback_my_sales_counteroffer_modal_toast_reject_content',
    defaultMessage:
      'The refurbisher will ship your item back to you within 2 business days.',
  },
}
