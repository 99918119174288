<template>
  <div>
    <p class="text-static-default-low mt-4 whitespace-pre-wrap">
      {{ description }}
    </p>
    <div class="mt-32">
      <FormGenerator
        v-if="id === PAYMENT_INFOS_OPTIONS.DRIVING_LICENCE_NUMBER"
        :extra-css="['!gap-28']"
        :form-config="formConfig.drivingLicense"
        :form-id
        :has-submit-button="false"
        @submit="handleSubmit"
      />
      <FormGenerator
        v-if="id === PAYMENT_INFOS_OPTIONS.SSN"
        :extra-css="['!gap-28']"
        :form-config="formConfig.ssn"
        :form-id
        :has-submit-button="false"
        @submit="handleSubmit"
      />
      <RevInfoBlock
        v-if="!isBmAgent"
        class="mt-32 whitespace-pre-wrap"
        :content="infoBlockDescription"
        :icon="IconLockLocked"
        :title="infoBlockTitle"
        variant="default"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import {
  PAYMENT_INFOS_OPTIONS,
  type PaymentInfosOptionsType,
} from '@backmarket/http-api/src/api-specs-buyback/customer/client'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import {
  matchingRegExp,
  maxLength,
  minLength,
  required,
} from '@ds/components/Form'
import { RevInfoBlock } from '@ds/components/InfoBlock'
import { IconLockLocked } from '@ds/icons/IconLockLocked'

import type {
  FormValues,
  Input,
} from '@backmarket/nuxt-layer-buyback/components/FormGenerator/FormGenerator.types'
import FormGenerator from '@backmarket/nuxt-layer-buyback/components/FormGenerator/FormGenerator.vue'
import translations from '@backmarket/nuxt-layer-buyback/components/IdentityInformations/IdentityInformations.translations'

const SSN_LENGTH = 4
const REGEX_DRIVING_LICENSE = /^[a-zA-Z0-9-]+$/

const props = withDefaults(
  defineProps<{
    formId: string
    id: PaymentInfosOptionsType
    isBmAgent?: boolean
  }>(),
  {
    isBmAgent: false,
  },
)

const i18n = useI18n()
const emit = defineEmits<{
  submit: [values: FormValues]
}>()

const formConfig: Record<string, Array<Input>> = {
  ssn: [
    {
      id: 'ssn',
      cols: 6,
      type: 'text',
      label: i18n(translations.ssnPreviewLabel),
      value: '',
      validation: [
        (accountNumber: string, values: FormValues) =>
          required(i18n(translations.required))(accountNumber, values, {}),

        (accountNumber: string, values: FormValues) =>
          maxLength(
            SSN_LENGTH,
            i18n(translations.lengthError, {
              length: 4,
            }),
          )(accountNumber, values, {}),

        (accountNumber: string, values: FormValues) =>
          minLength(
            SSN_LENGTH,
            i18n(translations.lengthError, {
              length: 4,
            }),
          )(accountNumber, values, {}),
      ],
    },
  ],
  drivingLicense: [
    {
      id: 'drivingLicense',
      cols: 6,
      type: 'text',
      label: i18n(translations.drivingLicensePreviewLabel),
      value: '',
      validation: [
        (drivingLicense: string, values: FormValues) =>
          required(i18n(translations.required))(drivingLicense, values, {}),
        (drivingLicense: string, values: FormValues) =>
          matchingRegExp(REGEX_DRIVING_LICENSE, i18n(translations.formatError))(
            drivingLicense,
            values,
            {},
          ),
      ],
    },
  ],
}

const description = computed(() => {
  if (props.isBmAgent) {
    return props.id === PAYMENT_INFOS_OPTIONS.SSN
      ? i18n(translations.ssn)
      : i18n(translations.drivingLicense)
  }

  return props.id === PAYMENT_INFOS_OPTIONS.SSN
    ? i18n(translations.descriptionSsn)
    : i18n(translations.descriptionDrivingLicense)
})

const infoBlockDescription = computed(() => {
  return props.id === PAYMENT_INFOS_OPTIONS.SSN
    ? i18n(translations.infoBlockDescriptionSsn)
    : i18n(translations.infoBlockDescriptionDrivingLicense)
})

const infoBlockTitle = computed(() => {
  return props.id === PAYMENT_INFOS_OPTIONS.SSN
    ? i18n(translations.infoBlockTitleSsn)
    : i18n(translations.infoBlockTitleDrivingLicense)
})

function handleSubmit(values: FormValues) {
  emit('submit', values)
}
</script>
