export default {
  back: {
    id: 'dashboard_know_your_customer_profile_upload_id_form_inputFile_verso',
    defaultMessage: 'Back',
  },
  errorToastTitle: {
    id: 'dashboard_know_your_customer_profile_upload_id_form_error_toast_title',
    defaultMessage: 'Hold up!',
  },
  fileFormatHint: {
    id: 'dashboard_know_your_customer_profile_upload_id_form_file_format_hint_error',
    defaultMessage: 'jpeg, png or pdf  files of less than {size}',
  },
  front: {
    id: 'dashboard_know_your_customer_profile_upload_id_form_inputFile_recto',
    defaultMessage: 'Front',
  },
  frontBackIdentical: {
    id: 'dashboard_know_your_customer_profile_upload_id_form_checkbox_text',
    defaultMessage: 'The front/back are in the same file',
  },
  myFile: {
    id: 'dashboard_know_your_customer_profile_upload_id_form_inputFile_myfile',
    defaultMessage: 'My file',
  },
  save: {
    id: 'dashboard_know_your_customer_profile_upload_id_form_save_button',
    defaultMessage: 'Save',
  },
  tooLargeError: {
    id: 'dashboard_know_your_customer_profile_upload_id_form_file_too_large_error',
    defaultMessage: 'Those photos are too large to upload. Give it another go.',
  },
  tooSmallError: {
    id: 'dashboard_know_your_customer_profile_upload_id_form_file_too_small_error',
    defaultMessage: 'Your file is smaller than the minimum size {size}.',
  },
  toastClose: {
    id: 'dashboard_know_your_customer_profile_upload_id_form_toast_close_alternative_text',
    defaultMessage: 'Close',
  },
  wrongFileTypeAndTooLargeError: {
    id: 'dashboard_know_your_customer_profile_upload_id_form_file_wrong_type_too_large_errors',
    defaultMessage:
      'Those photos have the wrong file type and too large to upload. Give it another go.',
  },
  wrongFileTypeError: {
    id: 'dashboard_know_your_customer_profile_upload_id_form_file_wrong_type_error',
    defaultMessage:
      'Those photos have the wrong file type. Give it another go.',
  },
  required: {
    id: 'buyback_shared_required_field',
    defaultMessage: 'This field is required',
  },
}
