import type { CounterOffer } from '@backmarket/http-api/src/api-specs-buyback/customer/getCounterOffer'
import type { RefurbisherCounterOfferDetails } from '@backmarket/http-api/src/api-specs-buyback/seller/refurbisher.types'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'

import { getFilenameFromUrl } from '@backmarket/nuxt-layer-buyback/utils/helpers'

const isPdf = (fileUrl: string) => {
  const { extension } = getFilenameFromUrl(fileUrl)

  return extension === 'pdf'
}

export const counterOfferAdapter = (
  counterOffer?: CounterOffer | RefurbisherCounterOfferDetails,
) => {
  const i18n = useI18n()

  const attachments = counterOffer?.attachments || []
  const formattedCounterOfferPrice = counterOffer?.counterOfferPrice
    ? i18n.price(counterOffer.counterOfferPrice)
    : ''
  const formattedPrice = counterOffer?.initialOfferPrice
    ? i18n.price(counterOffer.initialOfferPrice)
    : ''
  const comment = counterOffer?.comment || ''
  const remainingDaysString =
    counterOffer && 'remainingDays' in counterOffer
      ? counterOffer.remainingDays
      : '0'

  const reasonsList =
    counterOffer?.reasons.map((reason) => ({
      id: reason,
      label: reason,
    })) || []

  const imgAttachments = attachments.filter((attachment) => !isPdf(attachment))
  const pdfAttachments = attachments.filter(isPdf)

  const documentsList = pdfAttachments.map((documentUrl) => {
    const { name } = getFilenameFromUrl(documentUrl)

    return {
      id: documentUrl,
      src: documentUrl,
      displayName: name,
    }
  })

  const imagesList = imgAttachments.map((imgUrl) => ({
    id: imgUrl,
    src: imgUrl,
  }))

  return {
    reasonsList,
    documentsList,
    imagesList,
    formattedCounterOfferPrice,
    formattedPrice,
    comment,
    remainingDays: remainingDaysString,
  }
}
