<template>
  <div v-if="isPending">
    <RevLoadingScreen :text="i18n(translations.loadingDetails)" />
  </div>
  <div v-else>
    <div v-if="showForm" data-test="bank-details-form">
      <span v-if="!isBmAgent">
        {{ i18n(translations.description) }}
      </span>
      <FormGenerator
        :class="{ 'mt-32': !isBmAgent }"
        :form-config
        :form-id
        :has-submit-button="false"
        @submit="handleSubmit"
      />
    </div>
    <div v-else>
      {{ previewDescription }}
      <RevList class="mt-32" :hasExternalBorders="false">
        <RevListItemInteractive
          v-for="[label, description] in validApiValues"
          :key="label"
        >
          <template #label>
            {{ i18n(translations[label as keyof typeof translations]) }}
          </template>
          <template #description>
            {{ description }}
          </template>
        </RevListItemInteractive>
      </RevList>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import {
  type BankDetailsResponse,
  PAYMENT_INFOS_OPTIONS,
  type PaymentInfo,
  type PaymentInfosOptionsType,
} from '@backmarket/http-api/src/api-specs-buyback/customer/client'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevList } from '@ds/components/List'
import { RevListItemInteractive } from '@ds/components/ListItemInteractive'
import { RevLoadingScreen } from '@ds/components/LoadingScreen'

import type {
  FormValues,
  Input,
} from '@backmarket/nuxt-layer-buyback/components/FormGenerator/FormGenerator.types'
import FormGenerator from '@backmarket/nuxt-layer-buyback/components/FormGenerator/FormGenerator.vue'
import { generateAchForm } from '@backmarket/nuxt-layer-buyback/utils/generators/generateAchForm'
import { generateBacsForm } from '@backmarket/nuxt-layer-buyback/utils/generators/generateBacsForm'
import { generateIbanForm } from '@backmarket/nuxt-layer-buyback/utils/generators/generateIbanForm'

import translations from './BankDetails.translations'

function optionToBankType(option: PaymentInfosOptionsType | undefined) {
  switch (option) {
    case PAYMENT_INFOS_OPTIONS.BACS:
      return generateBacsForm().map((item) => ({
        ...item,
        cols: 6,
      }))
    case PAYMENT_INFOS_OPTIONS.ACH:
      return generateAchForm()
    case PAYMENT_INFOS_OPTIONS.SEPA:
    default:
      return generateIbanForm()
  }
}

const props = defineProps<{
  formId: string
  showForm: boolean
  bank: PaymentInfo | undefined
  isBmAgent?: boolean
  bankData: BankDetailsResponse | null
  isPending: boolean
}>()
const emit = defineEmits<{
  submit: [values: FormValues]
}>()
const i18n = useI18n()
const formConfig: Array<Input> = [...optionToBankType(props.bank?.options?.[0])]

const validApiValues = computed(() => {
  const { iban, routingNumber, accountNumber, sortCode } = props.bankData || {
    iban: null,
    accountNumber: null,
    routingNumber: null,
    sortCode: null,
  }

  return Object.entries({
    iban,
    routingNumber,
    accountNumber,
    sortCode,
  }).filter(([, val]) => Boolean(val))
})
const previewDescription = computed(() =>
  props.isBmAgent
    ? i18n(translations.agentPreviewDescription)
    : i18n(translations.previewDescription),
)

function handleSubmit(values: FormValues) {
  emit('submit', values)
}
</script>
