<template>
  <div>
    <div class="flex flex-col gap-16">
      <h3 class="heading-3">
        {{
          isKit
            ? i18n(translations.kitTitle)
            : i18n(translations.prepaidQrCodeLabelTitle)
        }}
      </h3>
      <p class="text-static-default-low body-1">
        {{
          isKit
            ? i18n(translations.kitFirstParagraph)
            : i18n(translations.prepaidQrCodeLabelParagraph)
        }}
      </p>
      <p v-if="isKit" class="text-static-default-low body-1">
        {{ i18n(translations.kitSecondParagraph) }}
      </p>
      <div
        class="flex gap-24"
        :class="{
          'flex-col': isPrepaidLabel,
          'flex-col-reverse': isQrCode || isKit,
        }"
      >
        <div class="flex flex-col gap-12">
          <p v-if="isPrepaidLabel" class="text-static-default-low body-1 mb-12">
            {{ i18n(translations.prepaidLabelParagraph) }}
          </p>

          <RevButton
            full-width="always"
            variant="secondary"
            @click="sendShippingLabelByEmail"
          >
            {{ i18n(translations.emailPrepaidLabel) }}
          </RevButton>

          <RevButton
            v-if="hasPrepaidLabel"
            full-width="always"
            target="_blank"
            :to="prepaidLabel"
            :variant="isPrepaidLabel ? 'primary' : 'secondary'"
          >
            {{ i18n(translations.downloadShippingLabel) }}
          </RevButton>
        </div>

        <div
          v-if="qrCodeUrl && !isKit"
          class="body-1-bold flex w-full flex-wrap items-center gap-12"
        >
          <RevDivider class="grow" />
          <span> {{ i18n(translations.shippingStepSeparator) }} </span>
          <RevDivider class="grow" />
        </div>

        <div v-if="qrCodeUrl" class="body-2 flex flex-col gap-16">
          <div
            class="border-static-brand-mid rounded-sm overflow-hidden border"
          >
            <div class="bg-surface-brand-mid body-1-bold px-64 py-12">
              {{ i18n(translations.shippingStepQrCodeShippingLabel) }}
            </div>
            <div class="px-72 py-32">
              <RevIllustration
                :alt="i18n(translations.shippingStepQrCodeShippingLabel)"
                class="m-auto"
                :height="180"
                sizes="180px"
                :src="qrCodeUrl"
                :width="180"
              />
            </div>
          </div>
          <RevButton
            v-if="isQrCode"
            full-width="always"
            target="_blank"
            :to="i18n(translations.shippingStepQrCodeLink)"
            variant="primary"
          >
            {{ i18n(translations.shippingStepQrCodeLinkText) }}
          </RevButton>
          <RevLink
            v-else
            target="_blank"
            :to="i18n(translations.shippingStepQrCodeLink)"
          >
            {{ i18n(translations.shippingStepQrCodeLinkText) }}
          </RevLink>
        </div>

        <div
          v-if="isKit"
          class="body-1-bold flex w-full flex-wrap items-center gap-12"
        >
          <RevDivider class="grow" />
          <span> {{ i18n(translations.shippingStepSeparator) }} </span>
          <RevDivider class="grow" />
        </div>
      </div>
    </div>

    <RevInfoBlock
      v-if="showInfoBlock"
      class="mt-32"
      :icon="IconSiren"
      :title="i18n(translations.infoBlockContent, { count: daysLeft })"
      variant="default"
    />
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import { BUYBACK_SHIPPING_MODE } from '@backmarket/http-api/src/api-specs-buyback/constants'
import { postShippingLabelEmail } from '@backmarket/http-api/src/api-specs-buyback/customer/postShippingLabelEmail'
import { useHttpFetch } from '@backmarket/nuxt-module-http/useHttpFetch'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useLogger } from '@backmarket/nuxt-module-logger/useLogger'
import { useTheToast } from '@backmarket/nuxt-module-toast/useTheToast'
import { isEmpty } from '@backmarket/utils/object/isEmpty'
import { RevButton } from '@ds/components/Button'
import { RevDivider } from '@ds/components/Divider'
import { RevIllustration } from '@ds/components/Illustration'
import { RevInfoBlock } from '@ds/components/InfoBlock'
import { RevLink } from '@ds/components/Link'
import { IconSiren } from '@ds/icons/IconSiren'

import translations from './ShippingStep.translations'

const props = defineProps<{
  shippingMode: string | undefined
  prepaidLabel: string | undefined
  daysLeft: number | undefined
  id: string
  qrCodeUrl: string | null | undefined
}>()

const i18n = useI18n()
const logger = useLogger()
const { openSuccessToast, openErrorToast } = useTheToast()

const isPrepaidLabel = computed(
  () => props.shippingMode === BUYBACK_SHIPPING_MODE.SELF_PACKAGED,
)
const hasPrepaidLabel = computed(() => !isEmpty(props.prepaidLabel))
const isQrCode = computed(
  () => props.shippingMode === BUYBACK_SHIPPING_MODE.QR_CODE,
)
const isKit = computed(() => props.shippingMode === BUYBACK_SHIPPING_MODE.KIT)
const showInfoBlock = computed(() => props.daysLeft !== undefined)

async function sendShippingLabelByEmail() {
  const { error, status } = await useHttpFetch(postShippingLabelEmail, {
    pathParams: {
      id: props.id,
    },
  })

  if (status.value === 'success') {
    openSuccessToast({
      title: i18n(translations.toastSuccessContent),
    })
  }

  if (error.value) {
    openErrorToast({
      title: i18n(translations.toastErrorContent),
    })

    logger.error(
      '[BUYBACK][API][MYSALES] Error requesting for the shipping label by email',
      {
        error: error.value,
        owners: ['bot-squad-circularity-order-lifecycle-front'],
      },
    )
  }
}
</script>
