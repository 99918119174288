import { createHttpEndpoint } from '../../utils'

export interface BankDetailsResponse {
  iban: string | null
  accountNumber: string | null
  routingNumber: string | null
  sortCode: string | null
  bankDetailsDocument: string | null
  isBankDetailsDocumentDeletable: boolean
}

export interface IdentityDocument {
  name: string
  url: string
}

export interface IdentityDocumentsResponse {
  identityFulfilled: boolean
  documents: {
    PASSPORT?: IdentityDocument
    ID_CARD?: IdentityDocument
    ID_CARD_FRONT?: IdentityDocument
    ID_CARD_BACK?: IdentityDocument
    DRIVING_LICENCE_FRONT?: IdentityDocument
    DRIVING_LICENCE_BACK?: IdentityDocument
    BANK_STATEMENT?: IdentityDocument
  }
  isIdentityDocumentDeletable: boolean
}

export interface BirthdateResponse {
  birthdate: string
}

export interface CustomerSsnResponse {
  socialSecurityNumber: string | null
}

export interface CustomerIdentityResponse {
  idType: string | null
  idNumber: string | null
}

export const PAYMENT_INFOS_TYPES = {
  IDENTITY_DOCUMENT: 'IDENTITY_DOCUMENT',
  BANK_DETAILS: 'BANK_DETAILS',
  PERSONAL_IDENTIFICATION: 'PERSONAL_IDENTIFICATION',
  DATE_OF_BIRTH: 'DATE_OF_BIRTH',
} as const

export const PAYMENT_INFOS_OPTIONS = {
  ID_CARD: 'ID_CARD',
  PASSPORT: 'PASSPORT',
  DRIVING_LICENCE: 'DRIVING_LICENCE',
  ACH: 'ACH',
  BACS: 'BACS',
  SEPA: 'SEPA',
  SSN: 'SSN',
  DRIVING_LICENCE_NUMBER: 'DRIVING_LICENCE_NUMBER',
} as const

export type PaymentInfosTypesType =
  (typeof PAYMENT_INFOS_TYPES)[keyof typeof PAYMENT_INFOS_TYPES]
export type PaymentInfosOptionsType =
  (typeof PAYMENT_INFOS_OPTIONS)[keyof typeof PAYMENT_INFOS_OPTIONS]

export interface PaymentInfo {
  type: PaymentInfosTypesType
  options: PaymentInfosOptionsType[]
  filled: boolean
}

export type PaymentInfoResponse = PaymentInfo[]

/**
 * @see {@link https://devportal.backmarket.io/catalog/default/api/client-api/definition#/bank%20details}
 */
export const getBankDetails = createHttpEndpoint<BankDetailsResponse>({
  method: 'GET',
  operationId: 'getClientBankDetails',
  path: '/bm/client/bank_details',
})

/**
 * @see {@link https://devportal.backmarket.io/catalog/default/api/client-api/definition#/identity%20documents/retrieveClientIdentityDocuments}
 */
export const getIdentityDocuments =
  createHttpEndpoint<IdentityDocumentsResponse>({
    method: 'GET',
    operationId: 'getClientIdentityDocuments',
    path: '/bm/client/identity_documents',
  })

/**
 * @see {@link https://devportal.backmarket.io/catalog/default/api/client-api/definition#/identity%20documents/uploadClientIdentityDocuments}
 */
export const postIdentityDocuments =
  createHttpEndpoint<IdentityDocumentsResponse>({
    method: 'POST',
    operationId: 'setIdentityDocuments',
    path: '/bm/client/identity_documents',
  })

/**
 * TBD
 */
export const getCustomerBirthdate = createHttpEndpoint<BirthdateResponse>({
  method: 'GET',
  operationId: 'getCustomerBirthdate',
  path: '/buyback/v1/customer/orders/:id/birthdate',
})

/**
 * TBD
 */
export const setCustomerBirthdate = createHttpEndpoint({
  method: 'PUT',
  operationId: 'getCustomerBirthdate',
  path: '/buyback/v1/customer/orders/:id/birthdate',
})

/**
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/client-api/definition#/social%20security%20number/getCustomerSocialSecurityNumber}
 *
 */
export const getCustomerSsn = createHttpEndpoint<CustomerSsnResponse>({
  method: 'GET',
  operationId: 'getCustomerSsn',
  path: '/bm/client/ssn',
})

/**
 * Update customer ssn number
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/client-api/definition#/social%20security%20number/putCustomerSocialSecurityNumber}
 */
export const putCustomerSsn = createHttpEndpoint<CustomerSsnResponse>({
  method: 'PUT',
  operationId: 'putCustomerSsn',
  path: '/bm/client/ssn',
})
