<template>
  <div class="mb-32 text-center">
    <h2 class="heading-1">{{ i18n(translations.heading) }}</h2>
    <p>{{ i18n(translations.subtitle) }}</p>
  </div>
  <RevContentBlock
    :button-label="i18n(translations.button)"
    button-loading-alternative-text="Loading..."
    button-variant="primary"
    class="bg-surface-default-low rounded-lg max-w-full grow p-32 lg:w-[1120px] lg:grow-0"
    :image-props="{
      alt: 'Money',
      src: '/img/buyback/no-sales-web.png',
      height: 445,
      width: 800,
    }"
    :title="i18n(translations.title)"
    title-variant="title2"
    :to="buybackHomeRoute"
  >
    <p class="body-1">
      {{ i18n(translations.paragraph) }}
    </p>
  </RevContentBlock>
</template>

<script setup lang="ts">
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevContentBlock } from '@ds/components/ContentBlock'

import { useRouteLocationWithLocale } from '~/composables/useRouteLocationWithLocale'
import { CMS } from '~/scopes/cms/routes-names'

import translations from './NoSales.translations'

const resolveLocalizedRoute = useRouteLocationWithLocale()

const buybackHomeRoute = resolveLocalizedRoute({
  name: CMS.BUYBACK,
  params: {
    pageName: 'home',
  },
})

const i18n = useI18n()
</script>
