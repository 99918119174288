<template>
  <div>
    <h3 class="heading-3 mt-4">{{ i18n(translations.personalDataTitle) }}</h3>
    <p class="text-static-default-low body-1 mt-12">
      {{ i18n(translations.personalDataSubtitle) }}
    </p>

    <div class="mt-32">
      <RevCheckbox
        v-for="item in checkboxes"
        :id="item.id"
        :key="item.id"
        v-model="item.value"
        :class="item.extraCss"
        :variant="item.variant"
      >
        <template #label>
          {{ item.label }}
        </template>

        <template #description>
          {{ item.description }}
        </template>
      </RevCheckbox>
    </div>

    <RevAccordionList
      class="mt-32"
      :has-dividers="false"
      :has-external-borders="false"
    >
      <RevAccordionItem>
        <template #title>
          {{ i18n(translations.personalDataAccordionTitle) }}
        </template>
        <template #body>
          <p class="text-static-default-low body-2">
            {{ i18n(translations.personalDataAccordionFirstParagraph) }}
          </p>
          <RevLink
            class="text-static-default-low mt-6 inline-block"
            target="_blank"
            :to="tradeinFaq?.backup"
          >
            {{ i18n(translations.personalDataAccordionBackupDataLink) }}
          </RevLink>
          <p class="text-static-default-low body-2 mt-36">
            {{ i18n(translations.personalDataAccordionSecondParagraph) }}
          </p>
          <RevLink
            class="text-static-default-low mt-36 inline-block"
            target="_blank"
            :to="tradeinFaq?.icloud"
          >
            {{ i18n(translations.personalDataAccordionICloudLink) }}
          </RevLink>
          <RevLink
            class="text-static-default-low inline-block"
            target="_blank"
            :to="tradeinFaq?.google"
          >
            {{ i18n(translations.personalDataAccordionGoogleLink) }}
          </RevLink>
        </template>
      </RevAccordionItem>
    </RevAccordionList>
  </div>
</template>

<script lang="ts" setup>
import { ref, watch } from 'vue'

import { useBuybackConfig } from '@backmarket/nuxt-layer-buyback/composables/config/useBuybackConfig'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevAccordionItem } from '@ds/components/AccordionItem'
import { RevAccordionList } from '@ds/components/AccordionList'
import { RevCheckbox } from '@ds/components/Checkbox'
import { VariantsEnum } from '@ds/components/Checkbox/Checkbox.constant'
import { RevLink } from '@ds/components/Link'

import translations from './PersonalDataStep.translations'

const emit = defineEmits<{
  'toggle-next': [isDisabled: boolean]
}>()
defineProps<{
  formId: string
}>()
const i18n = useI18n()
const { tradeinFaq } = useBuybackConfig()

const checkboxes = ref([
  {
    id: 'personalData',
    label: i18n(translations.personalDataCheckboxLabel),
    description: i18n(translations.personalDataCheckboxDescription),
    value: false,
    variant: VariantsEnum.Full,
  },
  {
    id: 'devicePhotos',

    label: i18n(translations.devicePhotosCheckboxLabel),
    description: i18n(translations.devicePhotosCheckboxDescription),
    value: false,
    extraCss: ['mt-16'],
    variant: VariantsEnum.Full,
  },
])

watch(checkboxes.value, (newState) => {
  if (newState.every(({ value }) => value === true)) {
    emit('toggle-next', false)
  } else {
    emit('toggle-next', true)
  }
})
</script>
