<template>
  <div>
    <p class="text-static-default-low body-1 mt-12 whitespace-pre-wrap">
      {{ description }}
    </p>

    <div class="mt-32">
      <RevRadio
        v-for="option in ids"
        :id="option.id"
        :key="option.id"
        v-model="radioId as string"
        class="mt-16"
        :value="option.id"
        variant="full"
      >
        <template #label>{{ option.label }}</template>
      </RevRadio>

      <RevInfoBlock
        v-if="!isBmAgent"
        class="mt-32 whitespace-pre-wrap"
        :content="i18n(translations.choiceInfoBlockContent)"
        :icon="IconLockLocked"
        :title="i18n(translations.choiceInfoBlockTitle)"
        variant="default"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import type { PaymentInfosOptionsType } from '@backmarket/http-api/src/api-specs-buyback/customer/client'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevInfoBlock } from '@ds/components/InfoBlock'
import { RevRadio } from '@ds/components/Radio'
import { IconLockLocked } from '@ds/icons/IconLockLocked'

import translations from '@backmarket/nuxt-layer-buyback/components/IdentityInformations/IdentityInformations.translations'

const props = withDefaults(
  defineProps<{
    ids: { id: PaymentInfosOptionsType; label: string }[]
    isBmAgent?: boolean
  }>(),
  {
    isBmAgent: false,
  },
)

const radioId = defineModel<PaymentInfosOptionsType>('id', {
  required: true,
})

const i18n = useI18n()

const description = computed(() =>
  props.isBmAgent
    ? i18n(translations.descriptionAgent)
    : i18n(translations.identityInformationsChoiceText),
)
</script>
