export default {
  rejectButton: {
    id: 'buyback_my_sales_counteroffer_modal_confirm_reject',
    defaultMessage: 'Reject counteroffer',
  },
  delayButton: {
    id: 'buyback_my_sales_counteroffer_modal_confirm_delay',
    defaultMessage: 'Not yet',
  },
  title: {
    id: 'buyback_my_sales_counteroffer_modal_confirm_title',
    defaultMessage: 'The power is yours',
  },
  description: {
    id: 'buyback_my_sales_counteroffer_modal_confirm_description',
    defaultMessage:
      'If you reject a counteroffer, you agree to cancel your sale and have the refurbisher send your item within 2 business days at no cost to you.',
  },
}
