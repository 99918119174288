export default {
  personalDataTitle: {
    id: 'buyback_my_sales_ship_item_personal_data_step_title_secondary',
    defaultMessage: 'Delete your stuff',
  },
  personalDataSubtitle: {
    id: 'buyback_my_sales_ship_item_personal_data_step_sub_title',
    defaultMessage:
      'To complete your sale, delete personal data from your device.',
  },
  personalDataCheckboxLabel: {
    id: 'buyback_my_sales_ship_item_personal_data_step_checkbox_label',
    defaultMessage: 'I have deleted my personal data',
  },
  personalDataCheckboxDescription: {
    id: 'buyback_my_sales_ship_item_personal_data_step_checkbox_description',
    defaultMessage:
      'If not, your device cannot be sold and your device will be sent back to you.',
  },
  personalDataRequired: {
    id: 'buyback_my_sales_ship_item_personal_data_step_checkbox_error',
    defaultMessage: 'You need to acknowledge you removed your data',
  },
  devicePhotosCheckboxLabel: {
    id: 'buyback_my_sales_ship_item_personal_data_step_photos_checkbox_label',
    defaultMessage:
      'I have taken photos of my device and package before shipping it out',
  },
  devicePhotosCheckboxDescription: {
    id: 'buyback_my_sales_ship_item_personal_data_step_photos_checkbox_description',
    defaultMessage:
      'Just in case anything unexpected happens in transit or if you receive a counteroffer from the refurbisher.',
  },
  personalDataAccordionTitle: {
    id: 'buyback_my_sales_ship_item_personal_data_step_accordion_title',
    defaultMessage: 'How to delete your data',
  },
  personalDataAccordionFirstParagraph: {
    id: 'buyback_my_sales_ship_item_personal_data_step_accordion_first_paragraph',
    defaultMessage:
      'First, back up your data before you delete it from your device completely.',
  },
  personalDataAccordionSecondParagraph: {
    id: 'buyback_my_sales_ship_item_personal_data_step_accordion_second_paragraph',
    defaultMessage:
      'Then, disconnect from all your accounts or the refurbisher will not accept your device.',
  },
  personalDataAccordionBackupDataLink: {
    id: 'buyback_my_sales_ship_item_personal_data_step_accordion_backup_link',
    defaultMessage: 'How to back up your data',
  },
  personalDataAccordionICloudLink: {
    id: 'buyback_my_sales_ship_item_personal_data_step_accordion_icloud_link',
    defaultMessage: 'How to disconnect your iCloud account',
  },
  personalDataAccordionGoogleLink: {
    id: 'buyback_my_sales_ship_item_personal_data_step_accordion_google_link',
    defaultMessage: 'How to disconect your Google account',
  },
}
