/**
 * ACH Banking Routing Number Validator
 * Strongly inspired by https://github.com/Chris-ScoutCorps/bank-routing-number-validator
 */

export function isRoutingNumberValid(routingNumber: string): boolean {
  // All 0's is technically a valid routing number, but it's inactive
  // As stated here: https://www.bankabanumbers.com/000000000.html
  if (routingNumber === '000000000') {
    return false
  }

  // Gotta be 9 digits
  const match = routingNumber.match('^\\d{9}$')
  if (!match) {
    return false
  }

  // The first two digits of the nine digit RTN must be in the ranges 00 through 12, 21 through 32, 61 through 72, or 80.
  // https://en.wikipedia.org/wiki/Routing_transit_number
  const firstTwo = +routingNumber.substring(0, 2)
  const firstTwoValid =
    (firstTwo >= 0 && firstTwo <= 12) ||
    (firstTwo >= 21 && firstTwo <= 32) ||
    (firstTwo >= 61 && firstTwo <= 72) ||
    firstTwo === 80
  if (!firstTwoValid) {
    return false
  }

  // This is the checksum
  // http://www.siccolo.com/Articles/SQLScripts/how-to-create-sql-to-calculate-routing-check-digit.html
  const weights = [3, 7, 1]
  let sum = 0
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < 8; i++) {
    sum += +routingNumber[i] * weights[i % 3]
  }

  return (10 - (sum % 10)) % 10 === +routingNumber[8]
}
