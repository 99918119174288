export default {
  toastTitle: {
    id: 'buyback_my_sales_shipping_label_toast_title',
    defaultMessage: 'Oups',
  },
  toastContent: {
    id: 'buyback_my_sales_shipping_label_in_progress',
    defaultMessage:
      'Your shipping label is being processed, please retry later.',
  },
}
