import {
  PAYMENT_INFOS_OPTIONS,
  type PaymentInfosOptionsType,
} from '@backmarket/http-api/src/api-specs-buyback/customer/client'
import type { I18n } from '@backmarket/nuxt-module-i18n/types'

import translations from './IdentityInformations.translations'

function inputsTitle(option: PaymentInfosOptionsType) {
  switch (option) {
    case PAYMENT_INFOS_OPTIONS.SSN:
      return translations.ssn
    case PAYMENT_INFOS_OPTIONS.DRIVING_LICENCE_NUMBER:
      return translations.drivingLicense
    default:
      return translations.drivingLicense
  }
}

export function retrieveIds(i18n: I18n, options?: PaymentInfosOptionsType[]) {
  return (
    options?.map((option) => {
      return {
        id: option,
        label: i18n(inputsTitle(option)),
      }
    }) || []
  )
}
