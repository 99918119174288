<template>
  <div>
    <div class="md:hidden">
      <div v-for="step in steps" :key="step.key">
        <RevSkeleton
          :aria-label="i18n(translations.loading)"
          class="mb-24"
          height="1.5rem"
          shape="rectangle"
          width="100%"
        />
      </div>
    </div>
    <div class="hidden md:block">
      <RevSkeleton
        :aria-label="i18n(translations.loading)"
        class="mb-8"
        height="1.25rem"
        shape="rectangle"
        width="100%"
      />
      <RevSkeleton
        :aria-label="i18n(translations.loading)"
        class="mb-8"
        height="1.25rem"
        shape="rectangle"
        width="100%"
      />
      <RevSkeleton
        :aria-label="i18n(translations.loading)"
        class="mb-10"
        height="1.25rem"
        shape="rectangle"
        width="25%"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevSkeleton } from '@ds/components/Skeleton'

import { type FormattedStatusTimeline } from '@backmarket/nuxt-layer-buyback/utils/adapters'

import translations from './StatusTracker.translations'

defineProps<{
  steps: Array<FormattedStatusTimeline>
}>()
const i18n = useI18n()
</script>
