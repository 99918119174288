import type { DjangoPaginatedResults } from '../../api-django'
import { createHttpEndpoint } from '../../utils'
import type {
  BuybackModelAction,
  BuybackModelInstruction,
  BuybackModelOrder,
  BuybackModelProduct,
} from '../models'

export type CustomerOrder = Omit<BuybackModelOrder, 'market'>

export interface Sale {
  product: BuybackModelProduct
  order: CustomerOrder
  actions: BuybackModelAction[]
  instruction?: BuybackModelInstruction
}

export type GetCustomerSalesPayload = DjangoPaginatedResults<Sale>

/**
 * Get buyback customer orders
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/buyback-bm-api/definition#tag/Customer/operation/get-bm-buyback-customer-orders}
 */

export const getCustomerSales = createHttpEndpoint<GetCustomerSalesPayload>({
  method: 'GET',
  operationId: 'getCustomerSales',
  path: '/bm/buyback/v1/customer/orders',
})
