import { useAsyncData } from '#imports'

import { getAsyncDataKey } from '@backmarket/nuxt-module-http-v2/getAsyncDataKey'
import { useHttpFetch } from '@backmarket/nuxt-module-http-v2/useHttpFetch'

export function useBirthdate(id: string) {
  const $httpFetch = useHttpFetch()
  const key = getAsyncDataKey('kyc-payment-info-birthday')

  return useAsyncData(key, () => {
    return $httpFetch('/buyback/v1/customer/orders/:id/birthdate', {
      pathParams: { id },
    })
  })
}

export function useBankDetails() {
  const $httpFetch = useHttpFetch()
  const key = getAsyncDataKey('kyc-payment-info-bank-details')

  return useAsyncData(key, () => {
    return $httpFetch('/bm/client/bank_details')
  })
}

export function useIdentityInformations() {
  const $httpFetch = useHttpFetch()
  const key = getAsyncDataKey('kyc-payment-info-bank-details')

  return useAsyncData(key, () => {
    return $httpFetch('/payout/customer/identity-number', {
      method: 'GET',
    })
  })
}

export async function useKycInformations(id: string) {
  const $httpFetch = useHttpFetch()
  const key = getAsyncDataKey('kyc-payment-info', {
    id,
  })

  return await useAsyncData(key, () => {
    return $httpFetch('/buyback/v1/customer/orders/:id/payment-info', {
      pathParams: { id },
    })
  })
}
