export default {
  accountNumberError: {
    id: 'buyback_merchant_update_ach_account_number_error',
    defaultMessage:
      'Your bank account number must be between { min } and { max } digits long.',
  },
  routingNumberError: {
    id: 'buyback_merchant_update_ach_routing_number_error',
    defaultMessage: 'Your routing number must be { length } digits long.',
  },
  routingNumberErrorGeneric: {
    id: 'buyback_merchant_update_ach_routing_number_error_generic',
    defaultMessage: 'Your routing number must be valid.',
  },
  addPayment: {
    id: 'buyback_merchant_update_ach_add_payment',
    defaultMessage: 'Add payment info',
  },
  accountHolder: {
    id: 'buyback_merchant_update_ach_account_holder',
    defaultMessage: 'Account holder',
  },
  routingNumber: {
    id: 'buyback_merchant_update_ach_routing_number',
    defaultMessage: 'Routing number',
  },
  required: {
    id: 'buyback_shared_required_field',
    defaultMessage: 'This field is required',
  },
  accountNumber: {
    id: 'buyback_merchant_update_ach_account_number',
    defaultMessage: 'Bank account number',
  },
  routingNumberDescription: {
    id: 'buyback_merchant_update_ach_routing_number_description',
    defaultMessage: 'E.g. 123456789',
  },
  accountNumberDescription: {
    id: 'buyback_merchant_update_ach_account_number_description',
    defaultMessage: 'E.g. 01234567890123',
  },
}
