import { createHttpEndpoint } from '../../utils'

/**
 * Send Shipping Label
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/buyback-bm-api/definition#tag/Customer/operation/sendShippingLabel}
 */
export const postShippingLabelEmail = createHttpEndpoint({
  method: 'POST',
  operationId: 'emailPrepaidLabel',
  path: '/bm/buyback/v1/customer/orders/:id/shipping',
})
