<template>
  <RevDialog :name :title="i18n(translations.title)">
    <template #body>
      <p class="mb-20">{{ i18n(translations.description) }}</p>
    </template>

    <template #footer>
      <div class="flex flex-col gap-8 lg:flex-row-reverse">
        <RevButton
          class="flex-1"
          :loading
          variant="primaryDestructive"
          @click="handleReject"
        >
          {{ i18n(translations.rejectButton) }}
        </RevButton>

        <RevButton class="flex-1" variant="secondary" @click="emit('cancel')">
          {{ i18n(translations.delayButton) }}
        </RevButton>
      </div>
    </template>
  </RevDialog>
</template>

<script setup lang="ts">
import { ref } from 'vue'

import type { HttpApiError } from '@backmarket/http-api'
import { putCounterOffer } from '@backmarket/http-api/src/api-specs-buyback/customer/customer'
import { $httpFetch } from '@backmarket/nuxt-module-http/$httpFetch'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useLogger } from '@backmarket/nuxt-module-logger/useLogger'
import { useTheToast } from '@backmarket/nuxt-module-toast/useTheToast'
import { RevButton } from '@ds/components/Button'
import { RevDialog } from '@ds/components/Dialog'
import { closeModal } from '@ds/components/ModalBase'

import translations from './ConfirmCounterOfferModal.translations'
import counterOfferTranslations from './CounterOfferModal.translations'

const emit = defineEmits(['reload-sales', 'close', 'cancel'])
const props = withDefaults(
  defineProps<{
    name: string
    id: string
  }>(),
  {},
)

const loading = ref(false)

const { openErrorToast, openSuccessToast } = useTheToast()
const i18n = useI18n()
const logger = useLogger()

async function handleReject() {
  try {
    loading.value = true
    await $httpFetch(putCounterOffer, {
      pathParams: {
        id: props.id,
      },
      body: {
        counterOfferStatus: 'Refused',
      },
    })
    openSuccessToast({
      title: i18n(counterOfferTranslations.toastCounterOfferRejectTitle),
      content: i18n(counterOfferTranslations.toastCounterOfferRejectContent),
    })
    emit('reload-sales')
    closeModal(props.name)
  } catch (err) {
    openErrorToast({
      title: i18n(counterOfferTranslations.toastCounterOfferErrorTitle),
      content: i18n(counterOfferTranslations.toastCounterOfferErrorContent),
    })

    logger.error(
      '[BUYBACK][API][MYSALES] Error requesting for the shipping label by email',
      {
        error: err as HttpApiError,
        owners: ['bot-squad-circularity-order-lifecycle-front'],
      },
    )
  } finally {
    loading.value = false
  }
}
</script>
