import { IconArchive } from '@ds/icons/IconArchive'
import { IconContact } from '@ds/icons/IconContact'
import { IconCreditCard } from '@ds/icons/IconCreditCard'
import { IconMoney } from '@ds/icons/IconMoney'

export const BUYBACK_MIN_AGE = 18
export const BUYBACK_MIN_YEAR = 1901

export const MY_SALES_ICONS = {
  box: IconArchive,
  card: IconCreditCard,
  bubble: IconContact,
  mat: IconMoney,
}

export const SHIPPING_STEPS = {
  ShippingStep: 'shippingStep',
  PersonalDataStep: 'personalDataStep',
}

export const INFO_BLOCK_VARIANT = 'blue'
