export default {
  title: {
    id: 'buyback_no_sales_title',
    defaultMessage: 'Let us throw bills at you, respectfully.',
  },
  surtitle: {
    id: 'buyback_no_sales_surtitle',
    defaultMessage: 'Got old tech?',
  },
  paragraph: {
    id: 'buyback_no_sales_paragraph',
    defaultMessage:
      'Get money you want for the tech you don’t need (even broken ones). That easy.',
  },
  button: {
    id: 'buyback_no_sales_button',
    defaultMessage: 'Search in all orders',
  },
  heading: {
    id: 'buyback_no_sales_heading',
    defaultMessage: 'Trade-ins',
  },
  subtitle: {
    id: 'buyback_no_sales_subtitle',
    defaultMessage: 'Your past and pending trade-ins.',
  },
}
