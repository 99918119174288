export default {
  stepCount: {
    id: 'buyback_my_sales_ship_item_step_count',
    defaultMessage: '{step} of {totalSteps}:',
  },
  personalData: {
    id: 'buyback_my_sales_ship_item_personal_data_step_title',
    defaultMessage: 'Hit refresh',
  },
  shipping: {
    id: 'buyback_my_sales_ship_item_shipping_step_title',
    defaultMessage: 'Wise you are',
  },
  nextButton: {
    id: 'buyback_my_sales_ship_item_next',
    defaultMessage: 'Next',
  },
  closeButton: {
    id: 'buyback_my_sales_ship_item_close',
    defaultMessage: 'Close',
  },
}
